/**
 * 复制链接
 * 
 * @Author: Focci
 * @Date: 2024-02-20 12:27:07
 * @Last Modified by: Focci
 * @Last Modified time: 2024-02-20 12:27:07
 */

import PropTypes from 'prop-types'
import useMessage from '@hook/useMessage'
import { useCallback } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useTranslations } from 'next-intl'

export default function CopyUrlShareButton({
  children,
  url
}) {
  const tl = useTranslations('live')
  const { message } = useMessage()
  const copyHandle = useCallback(() => message(tl('copied')), [message, tl])

  return (
    <CopyToClipboard text={url} onCopy={copyHandle}>
      {children}
    </CopyToClipboard>
  )
}

CopyUrlShareButton.propTypes = {
  children: PropTypes.node,
  url: PropTypes.string,
}

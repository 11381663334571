/**
 * 微信分享
 * 
 * @Author: Focci
 * @Date: 2024-02-20 11:33:19
 * @Last Modified by: Focci
 * @Last Modified time: 2024-02-20 11:33:19
 */

'use client'

import PropTypes from 'prop-types'
import useLazyComponent from '@hook/useLazyComponent'
import CircularProgress from '@mui/material/CircularProgress'
import { useCallback, useState } from 'react'
import { Dialog, DialogContent } from '@comp/material'
import { useTranslations } from 'next-intl'
import { isWechat, isWindow } from '@lib/utils'

function WechatShareDialog({ ready, url, Wedget }) {
  const tl = useTranslations('login')

  if(!ready) {
    return (
      <div className="w-[10rem] h-[10rem] flex items-center justify-center">
        <CircularProgress />
      </div>
    )
  }
  return (
    <div>
      <Wedget 
        value={url} 
        size={180}
        imageSettings={{
          src: 'https://s.hougarden.com/build/static/img/page/common/logo-bg-blue.png',
          width: 30,
          height: 30,
          excavate: true
        }}
      />
      <div className="text-f.8 text-center mt-4">
        {tl('wechatShare')}
      </div>
    </div>
  )
}

export default function WechatShareButton({
  children,
  url,
  onOpen = () => {},
  onClose = () => {}
}) {
  const [open, setOpen] = useState(false)
  const { Wedget, getWedget, ready } = useLazyComponent()

  const handleClick = useCallback(() => {
    setOpen(true)
    getWedget(() => import('qrcode.react'))
    onOpen()
  }, [getWedget, onOpen])

  const handleClose = useCallback(() => {
    onClose()
    setOpen(false)
  }, [onClose])

  if(!(isWindow && !isWechat())) {
    return null
  }

  return (
    <>
      <div
        onClick={handleClick}
        onKeyPress={handleClick}
        tabIndex={0}
        role="button"
        className="cursor-pointer"
      >
        {children}
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <WechatShareDialog
            url={url}
            ready={ready}
            Wedget={Wedget}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

WechatShareButton.propTypes = {
  children: PropTypes.node,
  url: PropTypes.string,
}

/**
 * StyledPopover
 * 
 * @Author: Focci
 * @Date: 2023-06-15 21:13:02
 * @Last Modified by: Focci
 * @Last Modified time: 2023-06-15 21:13:02
 */

// import PropTypes from 'prop-types'
import { forwardRef, useImperativeHandle, useState } from 'react'
import { ButtonBase, Popover } from '@comp/material'

function StyledPopover({
  trigger,
  children,
  type = 'click', // click, hover
  onOpen = () => {},
  onClose = () => {},
  buttonClassName = '',
  popoverProps,
  ...props
}, ref) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const eventName = { click: 'onClick', hover: 'onMouseEnter' }
  const customTriggerProps = { 
    [eventName[type]]: (e) => {
      setAnchorEl(e.currentTarget)
      onOpen({ close: () => setAnchorEl(null) })
    },
    ...props,
  }

  function handleClose() {
    setAnchorEl(null)
    onClose()
  }

  useImperativeHandle(ref, () => ({ close: handleClose }))

  return (
    <>
      <ButtonBase 
        className={buttonClassName} 
        {...customTriggerProps}
      >
        {trigger}
      </ButtonBase>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        {...popoverProps}
      >
        {children}
      </Popover>
    </>
  )
}

export default forwardRef(StyledPopover)

// StyledPopover.propTypes = {
//   trigger: PropTypes.node,
//   children: PropTypes.node,
//   type: PropTypes.oneOf(['click', 'hover']),
//   onOpen: PropTypes.func,
//   onClose: PropTypes.func,
//   buttonClassName: PropTypes.string,
//   popoverProps: PropTypes.object,
// }

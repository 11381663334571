/**
 * AgentAvatar
 * 
 * membershipLevel:
 * 
 * AGENT_MEMBERSHIP_LEVEL_PREMIUM = 3;
 * AGENT_MEMBERSHIP_LEVEL_FEATURE = 2;
 * AGENT_MEMBERSHIP_LEVEL_COMMON = 1;
 * 
 * @Author: Focci
 * @Date: 2024-08-28 15:06:54
 * @Last Modified by: Focci
 * @Last Modified time: 2024-08-28 15:06:54
 */

// import PropTypes from 'prop-types'
import AspectRatioCover from '@comp/AspectRatioCover'
import clsx from 'clsx'
import premiumAgentIcon from '@img/premium-agent-icon.png'
import Image from 'next/image'

export default function AgentAvatar({
  name,
  avatar,
  Target = AspectRatioCover,
  wrapperClassName = 'w-24 rounded-full',
  iconClassName = 'left-1/2 -translate-x-1/2 -bottom-1 max-w-full',
  borderClasssName = 'border border-2 border-[#fbcc61]',
  membershipLevel,
  premiumIcon,
  ...props
}) {
  const hasLevel = /^\d+$/.test(membershipLevel) && membershipLevel > 1

  return (
    <div 
      className={clsx([
        'relative',
        wrapperClassName,
        hasLevel && borderClasssName,
      ])}
    >
      <Target
        src={avatar}
        alt={name}
        {...props}
      />
      {hasLevel && membershipLevel > 2 && (premiumIcon || <Image 
        src={premiumAgentIcon}
        width={66}
        height={22}
        alt="Premium"
        className={clsx([
          'absolute pointer-events-none',
          iconClassName
        ])}
      />)}
    </div>
  )
}

// AgentAvatar.propTypes = {
//   className: PropTypes.string,
// }

/**
 * ShareDropdown
 * 
 * @Author: Focci
 * @Date: 2023-06-25 09:00:20
 * @Last Modified by: Focci
 * @Last Modified time: 2023-06-25 09:00:20
 */

'use client'

import PropTypes from 'prop-types'
import ShareIcon from '@mui/icons-material/Share'
import StyledPopover from '@comp/StyledPopover'
import clsx from 'clsx'
import SVGWechat from '@img/wechat.svg'
import SVGLink from '@img/link.svg'
import { useTranslations } from 'next-intl'
import { isWindow } from '@lib/utils'
import {
  EmailIcon,
  EmailShareButton,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  XIcon,
  TelegramShareButton,
  TelegramIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WeiboShareButton,
  WeiboIcon,
} from 'react-share'
import { useCallback, useRef } from 'react'
import CopyUrlShareButton from './CopyUrlShareButton'
import WechatShareButton from './WechatShareButton'

export function ShareTrigger({
  Icon = ShareIcon,
  className = 'hover:border-secondary hover:text-secondary'
}) {
  const tc = useTranslations('common')
  return (
    <div 
      className={clsx([
        'flex items-center border rounded-sm px-3 py-1 cursor-pointer gap-1',
        className
      ])}
    >
      <span className="whitespace-nowrap">{tc('share')}</span>
      <Icon sx={{ fontSize: '.8rem' }} />
    </div>
  )
}

export default function ShareDropdown({
  trigger = <ShareTrigger />,
  iconSize = 28,
  wrapperClassName = 'flex items-center px-3 py-1 transition-colors hover:bg-gray-100 cursor-pointer',
  textClassName = 'ml-2 text-gray-600',
  buttonClassName,
  shareData = {},
  onPanelClick = () => {},
  popoverProps
}) {
  const isWechat = useRef(false)
  const tl = useTranslations('login')

  const popoverRef = useRef()
  const handleClose = useCallback(() => popoverRef.current.close(), [])
  const handleWraperClick = useCallback(() => {
    onPanelClick()
    !isWechat.current && handleClose()
  }, [handleClose, onPanelClick])

  const handleWechatOpen = useCallback(() => isWechat.current = true, [])
  const handleWechatClose = useCallback(() => {
    isWechat.current = false
    handleClose()
  }, [handleClose])

  if(!isWindow) return null

  const url = shareData.url || window.location.href
  const title = shareData.text || window.document.title

  return (
    <StyledPopover 
      buttonClassName={buttonClassName}
      ref={popoverRef}
      trigger={trigger}
      {...popoverProps}
    >
      <div 
        className="flex flex-col my-2"
        onClick={handleWraperClick}
        onKeyPress={handleWraperClick}
        tabIndex={0}
        role="button"
      >
        <CopyUrlShareButton url={url}>
          <div className={wrapperClassName}>
            <span className="bg-cf0 rounded-full w-[1.4rem] h-[1.4rem] flex items-center justify-center">
              <SVGLink width=".8rem" />
            </span>
            <span className={textClassName}>{tl('copyurl')}</span>
          </div>
        </CopyUrlShareButton>
        <EmailShareButton url={url} subject={title}>
          <div className={wrapperClassName}>
            <EmailIcon size={iconSize} round />
            <span className={textClassName}>Email</span>
          </div>
        </EmailShareButton>
        <WechatShareButton 
          url={url} 
          onClose={handleWechatClose}
          onOpen={handleWechatOpen}
        >
          <div className={wrapperClassName}>
            <SVGWechat width={`${iconSize}px`} height={`${iconSize}px`} />
            <span className={textClassName}>{tl('wechat')}</span>
          </div>
        </WechatShareButton>
        <WeiboShareButton url={url} title={title}>
          <div className={wrapperClassName}>
            <WeiboIcon size={iconSize} round />
            <span className={textClassName}>{tl('weibo')}</span>
          </div>
        </WeiboShareButton>
        <TwitterShareButton url={url} title={title}>
          <div className={wrapperClassName}>
            <XIcon size={iconSize} round />
            <span className={textClassName}>Twitter</span>
          </div>
        </TwitterShareButton>
        <TelegramShareButton url={url} title={title}>
          <div className={wrapperClassName}>
            <TelegramIcon size={iconSize} round />
            <span className={textClassName}>Telegram</span>
          </div>
        </TelegramShareButton>
        <FacebookShareButton url={url} quote={title}>
          <div className={wrapperClassName}>
            <FacebookIcon size={iconSize} round />
            <span className={textClassName}>Facebook</span>
          </div>
        </FacebookShareButton>
        <LinkedinShareButton url={url} title={title}>
          <div className={wrapperClassName}>
            <LinkedinIcon size={iconSize} round />
            <span className={textClassName}>Linked in</span>
          </div>
        </LinkedinShareButton>
      </div>
    </StyledPopover>
  )
}

ShareDropdown.propTypes = {
  trigger: PropTypes.node,
  iconSize: PropTypes.number,
  wrapperClassName: PropTypes.string,
  textClassName: PropTypes.string,
  shareData: PropTypes.object,
}

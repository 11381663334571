/**
 * Favorite
 * 
 * @Author: Focci
 * @Date: 2023-08-15 09:07:00
 * @Last Modified by: Focci
 * @Last Modified time: 2023-08-15 09:07:00
 */

'use client'

import PropTypes from 'prop-types'
import Tooltip from '@mui/material/Tooltip'
import clsx from 'clsx'
import useApp from '@hook/useApp'
import useLogin from '@hook/useLogin'
import SVGAddlist from '@img/add-list.svg'
import SVGAddlisted from '@img/add-listed.svg'
import useDialog from '@hook/useDialog'
import { useCallback, useRef, useState } from 'react'
import { useTranslations } from 'next-intl'
import { ButtonBase } from '@comp/material'
import { fetchProxy, fetchDelete } from '@lib/fetch/client'
import { houseToGta, isAU } from '@lib/utils'
import useTracking from '@hook/useTracking'

export function FavoriteButton({
  hasLabel = false,
  customLabel = '',
  actived = false,
  disabled = false,
  activedClassName = 'bg-white text-[#d53c25]',
  className = 'bg-white',
}) {
  const tc = useTranslations('common')
  return (
    <div 
      className={clsx([
        'rounded-full flex items-center gap-1 justify-center whitespace-nowrap',
        actived ? activedClassName : className,
        hasLabel ? 'px-3 h-6 md:h-7' : 'px-2 h-9 w-9',
        disabled && 'opacity-50 pointer-events-none cursor-default'
      ])}
    >
      {
        actived 
          ? <SVGAddlisted width={hasLabel ? '.8rem' : '26px'} className="text-[#d53c25]" /> 
          : <SVGAddlist width={hasLabel ? '.8rem' : '26px'} />
      }
      {hasLabel && (
        customLabel || (actived ? tc('collectioned') : tc('collection'))
      )}
    </div>
  )
}

export function FavoriteCountBody({ count, actived, label }) {
  const th = useTranslations('house')

  return (
    <div className="flex flex-col items-center gap-1 leading-none">
      <div className="w-9 h-9 border rounded-full flex items-center justify-center">
        {actived ? <SVGAddlisted width="1rem" className="text-[#d53c25]" /> : <SVGAddlist width="1rem" />}
      </div>
      <span className="text-f.6">
        {label || th('favoriteTotal', { n: count })}
      </span>
    </div>
  )
}

export default function Favorite({
  houseId,
  suburbId,
  Trigger,
  hasLabel = false, // 是否展示文字
  buttonProps = null,
  house,
  favoriteTrackingPageType
}) {
  const tf = useTranslations('favorite')
  const { pushTrack } = useTracking()
  const { openDialog, closeDialog, Dialog } = useDialog()
  const { isLogin, openSigninDialog } = useLogin()
  const { favorites, setFavorites } = useApp()

  const [loading, setLoading] = useState(false)

  const panel = useRef('span')
  const actived = favorites.indexOf(houseId) > -1

  // 取消收藏
  const handleCancelFavorite = useCallback(() => {
    fetchProxy(
      fetchDelete(`user/favourite/${houseId}`),
      setLoading
    ).then(() => {
      setFavorites((prev) => prev.filter((id) => id !== houseId))
    })
  }, [houseId, setFavorites])

  // 点击添加收藏按钮
  const handleClick = useCallback(async () => {
    if(!isLogin) {
      openSigninDialog()
    } else if(actived) {
      handleCancelFavorite()
    } else {
      panel.current = (await import('./panel')).default
      openDialog()
    }
  }, [isLogin, actived, openSigninDialog, handleCancelFavorite, openDialog])

  const TriggerButton = Trigger || FavoriteButton
  const FavoritePanel = panel.current

  // 收藏成功回调
  const handleSuccess = useCallback(() => {
    if (house && isAU) {
      pushTrack('homely_add_to_collection', houseToGta(house, favoriteTrackingPageType, 'search_result'))
    }
    closeDialog()
  }, [closeDialog, favoriteTrackingPageType, house, pushTrack])
  
  return (
    <>
      <Tooltip 
        title={actived ? tf('cancelFavorite') : tf('addFavorite')} 
        placement="top" 
        arrow
      >
        <ButtonBase
          aria-label="Favorite"
          // className="rounded-full"
          disabled={loading}
          onClick={handleClick}
        >
          <TriggerButton
            actived={actived}
            hasLabel={hasLabel}
            disabled={loading}
            {...buttonProps}
          />
        </ButtonBase>
      </Tooltip>
      <Dialog>
        <FavoritePanel 
          houseId={houseId}
          house={house}
          suburbId={suburbId}
          onSuccess={handleSuccess}
        />
      </Dialog>
    </>
  )
}

Favorite.propTypes = {
  houseId: PropTypes.number,
  suburbId: PropTypes.number,
  Trigger: PropTypes.func,
  hasLabel: PropTypes.bool,
  buttonProps: PropTypes.object,
}

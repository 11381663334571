import { houseSearchType, typeOfId } from '@lib/config'
import { isArray, map } from 'lodash-es'

const enquiryTypes = {
  [houseSearchType.property]: 'house',
  [houseSearchType.rental]: 'rental',
  [houseSearchType.commercialForSale]: 'commercial_sale',
  [houseSearchType.commercialForLease]: 'commercial_lease',
  [houseSearchType.rural]: 'rural',
}

/**
 * 房源详情，发送邮件咨询基础数据
 */
export function getEnquiryProps(house) {
  const { houseId, agents, office, code } = house
  const tmp = { code, houseId }
  const { oss_avatar: officeIcon, officeColor, name, fontColor, id } = office || {}
  
  tmp.office = { officeIcon, officeColor, fontColor, name, id }
  tmp.trackParams = { houseId: houseId || 0, agencyOfficeId: house?.office?.id }
  tmp.agents = map(agents, (agent) => ({
    id: agent.id,
    name: `${agent.first_name} ${agent.last_name}`,
    phone: agent.phone_number,
    avatar: agent.oss_avatar,
    language: agent.language,
    slug: agent.slug,
    membershipLevel: agent.membership_level,
    officeId: id,
  }))
  
  tmp.house = house

  let type = enquiryTypes[house.type] || enquiryTypes[houseSearchType.property]

  // https://7thave.atlassian.net/browse/HT-3548
  // Lifestyle Property 和 Lifestyle Section
  if([47, 49].indexOf(house?.category?.id) > -1) {
    type = enquiryTypes.buy
  }

  tmp.formProps = {
    id: houseId,
    agentId: tmp.agents[0]?.id,
    code,
    type
  }

  return tmp
}
 
/**
 * 房源列表，发送邮件咨询基础数据
 */
export function getListingEnquiryProps(house) {
  const { code, id, officeIcon, officeColor, officeName: name, officeFontColor, typeId } = house
  const agents = isArray(house?.simpleAgents) ? house.simpleAgents : []
  const type = enquiryTypes[typeOfId[typeId]] || enquiryTypes[houseSearchType.property]
  
  const tmp = {
    houseId: id,
    code,
    office: { officeIcon, officeColor, fontColor: officeFontColor, name },
    trackParams: { houseId: id || 0 },
    agents,
    formProps: { id, type, agentId: agents[0]?.id, code },
    house
  }
  
  return tmp
}

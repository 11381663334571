/**
 * ShareButton
 * 
 * @Author: Focci
 * @Date: 2023-08-15 10:19:45
 * @Last Modified by: Focci
 * @Last Modified time: 2023-08-15 10:19:45
 */

import SVGShare from '@img/share.svg'
import { ShareTrigger } from '@comp/share/ShareDropdown'

export default function ShareButton() {
  return (
    <ShareTrigger
      Icon={SVGShare}
      className="
        h-6 md:h-7 !rounded-full bg-cf2 border-none
        flex-row-reverse
      "
    />
  )
}

export function ShareButtonSimple() {
  return (
    <ShareTrigger
      Icon={SVGShare}
      className="
        h-7 !rounded-full border-transparent
        flex-row-reverse
      "
    />
  )
}

function ShareTriggerIcon() {
  return (
    <div className="w-9 h-9 border rounded-full flex items-center justify-center">
      <SVGShare />
    </div>
  )
}

export function ShareButtonVertical() {
  return (
    <ShareTrigger
      Icon={ShareTriggerIcon}
      className="
        border-transparent flex-col flex-col-reverse text-f.6 leading-none
        !px-0 !py-0
      "
    />
  )
}
